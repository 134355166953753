import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import {
  Product,
  ProductRepository,
  ProductReview,
  Shops,
  Where,
} from '@infrab4a/connect';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BrandLeadService } from 'src/app/core/services/api/brand-lead.service';
import { ResizeAppService } from 'src/app/core/services/resize-application.service';
import { Banner } from 'src/app/shared/models/ banner';
import { LeadModel, LeadSourceEnum } from 'src/app/shared/models/lead.model';
import { ProductReviewModel } from 'src/app/shared/models/product-review-model';
import { ProductModel } from 'src/app/shared/models/product.model';
import { ResponseNewletter } from 'src/app/shared/models/responseNewletter';
import { Utils } from 'src/app/shared/utils/Utils';
import { environment as ENV } from 'src/environments/environment';

@Component({
  selector: 'malmo-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  products: Product[] = [];
  reviewedProducts: ProductModel[];
  reviewPoints: number[];
  isMobile: boolean;
  loading: boolean;
  isProduction = false;
  innerWidth: number;
  projectId;
  source;
  bannerItems: Banner[];
  display: boolean = true;
  loadingReviewedProducts: boolean;
  instagram = {
    username: 'usemalmo',
    token: ENV.instaToken,
  };
  companyValues = [
    {
      description: 'Curadoria que segue as tendências',
      svgPath: 'assets/img/our_value_1.svg',
    },
    {
      description: 'Design atemporal',
      svgPath: 'assets/img/our_value_2.svg',
    },
    {
      description: 'Produtos de qualidade com preço acessível',
      svgPath: 'assets/img/our_value_3.svg',
    },
  ];

  closeResult: string = '';
  formLead: FormGroup;
  loadingNewsletter: boolean;

  submitted = false;

  show = true;

  namePattern = '^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$';

  emailPattern =
    '^[a-zA-Z]+([\\.\\_\\-]?[a-zA-Z0-9])*@[a-zA-Z0-9]{2,}(\\.[a-zA-Z]{2,4}){1,2}$';

  user_email = '';

  @ViewChild('content') myModal: any;

  constructor(
    @Inject('ProductRepository') private _fireStore: ProductRepository,
    private _resizeAppService: ResizeAppService,
    private meta: Meta,
    private titleService: Title,
    private modalService: NgbModal,
    private _brandLeadService: BrandLeadService,
    private fb: FormBuilder
  ) {
    this.isProduction = ENV.production;

    this.isMobile = this._resizeAppService.isMobileWidthSize;
    this.innerWidth = window.innerWidth;
    this.loading = false;
  }

  ngAfterViewInit() {
    this.open(this.myModal);
    this.initForm();
    this.loading = false;
  }

  ngOnInit(): void {
    this.projectId = ENV.firebase.projectId;
    this.source = 'Malmo';
    this.getProducts();
    this.getReviewedProducts();
    this._resizeAppService
      .onResize()
      .subscribe((innerWidth) => (this.innerWidth = innerWidth));

    this.titleService.setTitle(
      'Malmo | Acessórios masculinos estilosos e de qualidade'
    );
    this.meta.addTag({
      name: 'author',
      content: 'B4A',
    });

    this.meta.updateTag({
      name: 'description',
      content:
        'A Malmo oferece os melhores acessórios masculinos, produtos de qualidade, com preço acessível. Os acessórios ousados e modernos pra quem quer inovar no visual!',
    });
  }

  handleSlideToSection(section: string): void {
    Utils.slideToSection(section);
  }

  private async getProducts(): Promise<void> {
    try {
      this.loading = true;

      const value = 'Malmo';
      const limit = 15;
      const productsResponse = await this._fireStore.find({
        filters: {
          brand: {
            operator: Where.EQUALS,
            value,
          },
          published: {
            operator: Where.EQUALS,
            value: true,
          },
          stock: {
            quantity: {
              operator: Where.GT,
              value: 0,
            },
          },
        },
      });

      this.products = productsResponse.data;

      this.loading = false;
    } catch (err) {
      console.error(err);
      this.loading = false;
    }
  }

  getSkeletonTheme(): Object {
    return {
      width: '210px',
      height: '315px',
      'border-radius': '4px',
      'background-color': '#f2f2f2',
    };
  }

  getSkeletonCount(): number {
    if (this.innerWidth <= 425) {
      return 1;
    } else if (this.innerWidth > 425 && this.innerWidth <= 585) {
      return 2;
    } else if (this.innerWidth > 585 && this.innerWidth <= 768) {
      return 3;
    } else if (this.innerWidth > 768 && this.innerWidth <= 1366) {
      return 4;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1520) {
      return 5;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1640) {
      return 6;
    } else if (this.innerWidth > 1640 && this.innerWidth <= 1920) {
      return 7;
    } else {
      return 8;
    }
  }

  private filterUserReviewProduct(
    slug: string,
    reviewProduct: ProductReview
  ): ProductReview {
    if (
      reviewProduct.author === 'Larissa Xavier' &&
      slug === 'pulseira-malmo-copacabana-hematita'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Andressa Kracke' &&
      slug === 'suporte-para-acessorios-malmo-cru'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Liliane Silva' &&
      slug === 'bone-malmo-trucker-suede'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Bárbara Leão' &&
      slug === 'carteira-malmo-leif'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Vinicius S.' &&
      slug === 'pulseira-malmo-tijuca'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Jonatas Campos' &&
      slug === 'colar-malmo-nazar'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'Leonardo' &&
      slug === 'colar-malmo-destiny'
    ) {
      return reviewProduct;
    } else if (
      reviewProduct.author === 'solange broetto' &&
      slug === 'meia-malmo-poa-classic'
    ) {
      return reviewProduct;
    } else {
      return null;
    }
  }

  private async getReviewedProducts(): Promise<void> {
    const value = `Malmo`;
    const productsSlugs = [
      'pulseira-malmo-copacabana-hematita',
      'suporte-para-acessorios-malmo-cru',
      'bone-malmo-trucker-suede',
      'carteira-malmo-leif',
      'pulseira-malmo-tijuca',
      'colar-malmo-destiny',
      'colar-malmo-nazar',
      'meia-malmo-poa-classic',
    ];
    try {
      const reviewedProductsResponse = await this._fireStore.find({
        filters: {
          brand: {
            operator: Where.EQUALS,
            value,
          },
          published: {
            operator: Where.EQUALS,
            value: true,
          },
        },
        fields: ["id", "sku", "brand", "name", "images", "reviews", "price", "slug", "tags"]
      });

      const reviewedProducts: ProductModel[] = reviewedProductsResponse.data
        .filter((product) => productsSlugs.includes(product.slug))
        .map((product) => {
          return {
            id: product.id,
            sku: product.sku,
            name: product.name,
            img: product.images,
            reviews: product.reviews
              .filter((productReview) =>
                this.filterUserReviewProduct(product.slug, productReview)
              )
              .map((productReview) => {
                return {
                  author: productReview.author,
                  email: productReview.email,
                  location: productReview.location,
                  rate: productReview.rate,
                  review: productReview.review,
                  status: productReview.status,
                  title: productReview.title,
                  shop: Shops.MENSMARKET,
                };
              }),
            cost: {
              price: product.price.price,
              fullPrice: product.price.fullPrice,
            },
            slug: product.slug,
            tags: product.tags ?? [],
          };
        });

      this.reviewedProducts = reviewedProducts;
      let qtd: number;
      if (this.innerWidth >= 2000) {
        qtd = this.reviewedProducts?.length / 6;
      } else {
        qtd = this.reviewedProducts?.length / 2;
      }
      const dots = [];
      for (let i = 0; i < qtd; i++) {
        dots.push(i);
      }
      this.reviewPoints = dots;
      this.loadingReviewedProducts = false;
    } catch (err) {
      console.error(err);
      this.loading = false;
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  open(content: any) {
    this.modalService.open(content, { centered: true, size: 'xl' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  initForm(): void {
    this.formLead = this.fb.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.namePattern),
        ]),
      ],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }

  get formLeadControl() {
    return this.formLead.controls;
  }

  getClass(formLeadControl) {
    if (formLeadControl === this.formLeadControl.name) {
      if (this.formLeadControl.name.valid) {
        return 'success';
      }
      if (
        (this.formLeadControl.name.touched || this.submitted) &&
        (this.formLeadControl.name.errors?.required ||
          this.formLeadControl.name.errors?.pattern)
      ) {
        return 'error';
      } else {
        return '';
      }
    }
    if (formLeadControl === this.formLeadControl.email) {
      if (this.formLeadControl.email.valid) {
        return 'success';
      }
      if (
        (this.formLeadControl.email.touched || this.submitted) &&
        (this.formLeadControl.email.errors?.required ||
          this.formLeadControl.email.errors?.pattern)
      ) {
        return 'error';
      } else {
        return '';
      }
    } else {
      return;
    }
  }

  setObject() {
    return {
      name: this.formLead.value.name,
      email: this.formLead.value.email,
      source: LeadSourceEnum.malmo,
      acceptsNewsletter: true,
    };
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.formLead.valid) {
      try {
        const requestPayload: LeadModel = this.setObject() as LeadModel;
        this.loadingNewsletter = true;
        await this._brandLeadService
          .registerNewsletter(requestPayload)
          .then((res: ResponseNewletter) => {
            res;
            this.submitted = false;
            this.user_email = requestPayload.email;
            this.formLead.reset();
          });
      } catch (error) {
        console.log(error);
        this.loadingNewsletter = false;
      } finally {
        this.loadingNewsletter = false;
        this.submitted = false;
        this.show = !this.show;
      }
    }
  }
}
