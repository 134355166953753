<div class="wrapper">
    <h2 class="wrapper__title">Descubra seu Estilo</h2>

    <div class="content">
        <a
            target="_blank"
            href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=94&profile=false&brandIds=&prices=&catFilter=classico"
        >
            <div class="circle">
                <img
                    class="circle__image"
                    src="assets/img/fashion/estilo1.png"
                    alt="Alta Qualidade"
                >
                <strong class="circle__title">Clássico</strong>
                <p class="circle__description">
                    Curte acessórios, mas prefere ficar no básico e elegante? Vem ver
          suas opções
                </p>
            </div>
        </a>

        <a
            target="_blank"
            href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=139&profile=false&brandIds=&prices=&catFilter=r%C3%BAstico"
        >
            <div class="circle">
                <img
                    class="circle__image"
                    src="assets/img/fashion/estilo2.jpg"
                    alt="Vegano e cruelty free"
                >
                <strong class="circle__title">Rústico</strong>
                <p class="circle__description">
                    Para quem curte masculinidade e um visual mais rústico e sóbrio
                </p>
                <p></p>
            </div>
        </a>

        <a
            target="_blank"
            href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=139&profile=false&brandIds=&prices=&catFilter=moderno"
        >
            <div class="circle">
                <img
                    class="circle__image"
                    src="assets/img/fashion/estilo3.jpg"
                    alt="Rotina descomplicada"
                >
                <strong class="circle__title">Moderno</strong>
                <p class="circle__description">
                    Os acessórios ousados e modernos pra quem quer inovar no visual
                </p>
            </div>
        </a>
    </div>

    <a
        href="https://mensmarket.com.br/collections/malmo"
        target="_blank"
    >
        <button
            id="fashion-block__button"
            class="btn-secondary wrapper__button"
        >
            Conheça a Linha
        </button>
    </a>
</div>
