import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { carouselProductDesktop, carouselProductMobile } from '../../constants/slides';
import * as componentsB4a from '@infrab4a/components-b4a';import { Product } from '@infrab4a/connect';
import { ResizeAppService } from 'src/app/core/services/resize-application.service';

@Component({
  selector: 'malmo-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductsComponent {

  @Input() products: Product[];
  @Input() loading: boolean;
  innerWidth: number;
  isMobile = false;

  carousel: componentsB4a.B4aCarouselComponent<any>;
  carouselIsFirst = true;
  carouselIsLast = false;
  points: number[] = [];

  @ViewChild('myCarousel')
  set _myCarousel(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
      this.carousel = myCarousel;
      setTimeout(() => {
          this.points = myCarousel?.pointNumbers;
      });
    }
    carouselTile: componentsB4a.B4aCarouselConfig;

    carouselTileMobile: componentsB4a.B4aCarouselConfig;

    constructor(private _resizeAppService: ResizeAppService) {
      this.isMobile = this._resizeAppService.isMobileWidthSize;
      this.carouselTile = carouselProductDesktop;
      this.carouselTileMobile = carouselProductMobile;
    }

    onCarouselMove(myCarousel: componentsB4a.B4aCarouselComponent<any>) {
      this.carouselIsFirst = myCarousel.isFirst;
      this.carouselIsLast = myCarousel.isLast;
      if (myCarousel.pointNumbers) {
          this.points = myCarousel.pointNumbers;
      } else {
          this.points = [];
      }
    }

  getSkeletonTheme(): Object {
    return {
      width: '210px',
      height: '315px',
      'border-radius': '4px',
      'background-color': '#f2f2f2',
    };
  }

  getSkeletonCount(): number {
    if (this.innerWidth <= 425) {
      return 1;
    } else if (this.innerWidth > 425 && this.innerWidth <= 585) {
      return 2;
    } else if (this.innerWidth > 585 && this.innerWidth <= 768) {
      return 3;
    } else if (this.innerWidth > 768 && this.innerWidth <= 1366) {
      return 4;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1520) {
      return 5;
    } else if (this.innerWidth > 1366 && this.innerWidth <= 1640) {
      return 6;
    } else if (this.innerWidth > 1640 && this.innerWidth <= 1920) {
      return 7;
    } else {
      return 8;
    }
  }
}
