<div id="malmo-about-us">
    <main>
        <section id="primary-section">
            <div class="wrapper">
                <h1>Sobre a Malmo</h1>
            </div>
        </section>
        <section id="second-section">
            <div class="wrapper">
                <img
                    src="assets/img/about-us/vision.jpg"
                    alt="Nossa visão"
                    loading="lazy"
                    class="wrapper__image-block"
                >

                <img
                    src="assets/img/about-us/vision-mobile.jpg"
                    alt="Hidratação"
                    loading="lazy"
                    class="wrapper__image-block-mobile"
                >

                <div class="wrapper__info">
                    <h2>Nossa visão</h2>
                    <p>
                        A Malmo tem como objetivo conectar você com o seu estilo,
                      seja ele clássico, rústico ou moderno. Trabalhamos no
                      desenvolvimento de acessórios de qualidade, ligados às
                      tendências e com custo-benefício pros nossos consumidores,
                      como forma de aprimorar o seu visual com peças estilosas,
                      versáteis e que cabem no seu bolso. Nunca se esqueça: a
                      beleza está nos detalhes. De que basta uma roupa bonita sem
                      bons acessórios?
                    </p>
                </div>
            </div>

            <div class="wrapper">
                <img
                    src="assets/img/about-us/history.jpg"
                    alt="Hidratação"
                    loading="lazy"
                    class="wrapper__image-block"
                >
                <img
                    src="assets/img/about-us/history-mobile.jpg"
                    alt="Hidratação"
                    loading="lazy"
                    class="wrapper__image-block-mobile"
                >

                <div class="wrapper__info-2">
                    <h2>Nossa história</h2>
                    <p>
                        A Malmo nasceu em 2014 a partir da Men’s Market, o e-commerce de
                      cosméticos masculinos líder na América Latina, com o propósito de
                      alinhar moda e beleza para homens. O nome veio de uma curiosa história
                       que o fundador da Malmo leu sobre um empreendedor que definiu o nome
                       de sua marca ao girar um globo terrestre e apontar ao acaso uma cidade.
                        Feito o mesmo, o nome escolhido foi de uma cidade na Suécia: Malmo.
                    </p>
                </div>
            </div>
            <div class="wrapper">
                <img
                    src="assets/img/about-us/weBelieve.jpg"
                    alt="No que acreditamos"
                    loading="lazy"
                    class="wrapper__image-block"
                >

                <img
                    src="assets/img/about-us/weBelieve-mobile.jpg"
                    alt="Hidratação"
                    loading="lazy"
                    class="wrapper__image-block-mobile"
                >

                <div class="vertical-block">
                    <h2>No que acreditamos</h2>
                    <div>
                        <div class="numbers-circle">
                            <img
                                src="assets/img/about-us/value_1.svg"
                                alt="Design"
                                loading="lazy"
                                class="wrapper__image"
                            >
                        </div>
                        <h3>
                            Aprimorar o estilo do homem contemporâneo
                        </h3>
                    </div>
                    <div>
                        <div class="numbers-circle">
                            <img
                                src="assets/img/about-us/value_2.svg"
                                alt="Design"
                                loading="lazy"
                                class="wrapper__image"
                            >
                        </div>
                        <h3>Oferecer curadoria focada em tendências</h3>
                    </div>
                    <div>
                        <div class="numbers-circle">
                            <img
                                src="assets/img/about-us/value_3.svg"
                                alt="Custo benefício"
                                loading="lazy"
                                class="wrapper__image"
                            >
                        </div>
                        <h3>
                            Acessibilizar produtos de qualidade com bom custo-benefício
                        </h3>
                    </div>
                </div>
            </div>
        </section>
        <section id="third-section">
            <malmo-instagram-carousel [innerWidth]="innerWidth"></malmo-instagram-carousel>
        </section>
        <section id="fourth-section">
            <malmo-newsletter></malmo-newsletter>
        </section>
    </main>
</div>
