<div id="categories-component">
    <div class="wrapper">
        <h2 class="wrapper__title">Categorias</h2>

        <div class="content">
            <a
                target="_blank"
                href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=94&profile=false&brandIds=&prices=&catFilter=pulseiras"
            >
                <div class="retangle retangle__bracelet">
                    <span class="retangle__name">Pulseiras</span>
                </div>
            </a>
            <a
                target="_blank"
                href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=94&profile=false&brandIds=&prices=&catFilter=colares"
            >
                <div class="retangle retangle__necklaces">
                    <span class="retangle__name">Colares</span>
                </div>
            </a>
            <a
                target="_blank"
                href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=94&profile=false&brandIds=&prices=&catFilter=carteiras"
            >
                <div class="retangle retangle__wallet">
                    <span class="retangle__name">Carteiras</span>
                </div>
            </a>
            <a
                target="_blank"
                href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=94&profile=false&brandIds=&prices=&catFilter=vestu%C3%A1rio"
            >
                <div class="retangle retangle__clothing">
                    <span class="retangle__name">Vestuário</span>
                </div>
            </a>
        </div>
    </div>
</div>
