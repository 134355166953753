export enum LeadSourceEnum {
  malmo = 'Malmo',
}

export interface LeadModel {
  name: string;
  email: string;
  source: LeadSourceEnum;
  acceptsNewsletter: boolean;
}
