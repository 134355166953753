import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';

import { ProductModel } from '../../models/product.model';

@Component({
  selector: 'malmo-review-card',
  templateUrl: './review-card.component.html',
  styleUrls: ['./review-card.component.scss'],
})
export class ReviewCardComponent implements OnInit {
  @Input() reviewedProduct: ProductModel;
  ratingAverage: number;
  ratingCount: number;
  star: TemplateRef<unknown>;

  constructor(private ngbConfig: NgbRatingConfig) {
    this.ngbConfig.max = 5;
  }

  ngOnInit() {
    this.ratingCount =
      this.reviewedProduct?.reviews?.filter((r) => r.status).length || 0;
    this.ratingAverage = this.ratingCount
      ? this.reviewedProduct?.reviews
          ?.filter((r) => r.status)
          .reduce((sum, review) => (sum += review.rate), 0) / this.ratingCount
      : 0;
  }
}
