export const environment = {
  API_URL: 'https://southamerica-east1-b4aprodution.cloudfunctions.net',
  production: true,
  firebase: {
    apiKey: 'AIzaSyCJf1tkjIN1I1ZegazckSiwS0ik4Lequwo',
    authDomain: 'b4aprodution.firebaseapp.com',
    projectId: 'b4aprodution',
    storageBucket: 'b4aprodution.appspot.com',
    messagingSenderId: '458755317337',
    appId: '1:458755317337:web:fc98697332fada8d021b48',
    measurementId: 'G-T6E6L3J73M',
  },
  firebaseRegion: 'southamerica-east1',
  gaTackingId: 'G-DRQND1G9EJ',

  instaToken:
    'IGQVJYZA0xwTU1vVW9sQnFkVW9yVjVCZAm1ZAWkNySERvZAVItWW9UYndTUllRU2tYdHdpa2MxNHhPdzdyLWh0NUJQb0lfMThLMjdpWVB3LUZAXbm42eFVyTWdoam1IeWFrNm1OZAGlJNmg4VnRueXRnd1F4ZAwZDZD',
  instaApi: 'https://graph.instagram.com/me/media?access_token=',
  elasticSearch: {
    url: 'https://e4a30e39227142719915dfb4bd5240b5.us-central1.gcp.cloud.es.io:443',
    credential: 'MnBJVHY0Y0JtbEZ2dFBZRE82NGQ6WHdXQ1ZwTkhRQUNvS2s5WTNGSUNCUQ=='
  },
  hasura: {
    endpoint: 'https://hasura-products-36m4jjtkna-rj.a.run.app/v1/graphql',
    adminSecret: 'b171ab4d722455b03648ed462b41f8c2'
  },
  apiUrl: 'https://subscription-api-prod.glambox.com.br/'
};
