<div id="malmo-fashion">
    <main>
        <section id="primary-section">
            <div class="wrapper">
                <div class="content">
                    <h1 class="content__title">Você sabe qual é o seu estilo?</h1>
                    <p class="content__description">
                        Descubra qual o estilo mais combina com a sua personalidade! Os
            acessórios que mais combinarem com seu visual vão transmitir a
            mensagem que você quer passar
                    </p>
                    <a
                        href="https://mensmarket.com.br/collections/malmo"
                        target="_blank"
                    >
                        <button class="btn-primary">Conheça a Linha</button>
                    </a>
                </div>
            </div>
        </section>

        <section id="second-section">
            <div class="wrapper">
                <img
                    src="assets/img/fashion/estilo-classico.jpg"
                    alt="Estilo Clássico"
                    loading="lazy"
                    class="wrapper__image"
                >

                <div class="wrapper__info">
                    <h2>Estilo Clássico</h2>
                    <p>
                        A definição da pessoa que gosta de se vestir de maneira mais
            atemporal, básica e conservadora, não arrisca demais com tendências
            e se acostuma a usar poucos acessórios, sempre discretos. Em cores
            preta e marrom, preferencialmente desenhos mais simples em couro ou
            tecido.
                    </p>
                    <a
                        href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=94&profile=false&brandIds=&prices=&catFilter=classico"
                        target="_blank"
                    >
                        <button class="btn-tertiary">Conheça a Linha</button>
                    </a>
                </div>
            </div>

            <div class="wrapper">
                <img
                    src="assets/img/fashion/estilo-rustico.jpg"
                    alt="Estilo Durão"
                    loading="lazy"
                    class="wrapper__image"
                >

                <div class="wrapper__info">
                    <h2>Estilo Rústico</h2>
                    <p>
                        Estilo mais marcante, despojado e masculino. Pra quem curte um
            visual mais rocker, viking e militar. Os acessórios fazem parte do
            estilo de vestir e do dia a dia. Cores escuras, verdes, marrons,
            cinzas e pretos, e materiais como couros e peles predominam.
                    </p>
                    <a
                        href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=139&profile=false&brandIds=&prices=&catFilter=r%C3%BAstico"
                        target="_blank"
                    >
                        <button class="btn-tertiary">Conheça a Linha</button>
                    </a>
                </div>
            </div>

            <div class="wrapper">
                <img
                    src="assets/img/fashion/estilo-moderno.jpg"
                    alt="Estilo Moderno"
                    loading="lazy"
                    class="wrapper__image"
                >

                <div class="wrapper__info">
                    <h2>Estilo Moderno</h2>
                    <p>
                        A definição do caçador de tendências, é antenado na moda e
            novidades. Pode ter diferentes estilos de roupas de acordo com o
            momento: seja no trabalho, no happy hour ou balada. Usa acessórios
            como parte do outfit, buscando sempre ser o mais descolado.
                    </p>
                    <a
                        href="https://mensmarket.com.br/collections/malmo?page=1&scrollY=139&profile=false&brandIds=&prices=&catFilter=moderno"
                        target="_blank"
                    >
                        <button class="btn-tertiary">Conheça a Linha</button>
                    </a>
                </div>
            </div>
        </section>

        <section id="third-section">
            <malmo-instagram-carousel [innerWidth]="innerWidth"></malmo-instagram-carousel>
        </section>
    </main>
</div>
