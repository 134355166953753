import * as componentsB4a from '@infrab4a/components-b4a';

export const carouselBanner: componentsB4a.B4aCarouselConfig = {
  grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
  slide: 1,
  speed: 250,
  point: {
    visible: true
  },
  loop: true,
  load: 2,
  velocity: 0,
  touch: true,
  easing: 'cubic-bezier(0, 0, 0.2, 1)',
  interval: { timing: 5000 },
  animation: 'lazy'
};

export const carouselProductDesktop: componentsB4a.B4aCarouselConfig = {
  grid: { xs: 1, sm: 3, md: 3, lg: 5, all: 0 },
  slide: 5,
  speed: 250,
  point: {
    visible: true
  },
  load: 2,
  velocity: 0,
  touch: true,
  easing: 'cubic-bezier(0, 0, 0.2, 1)',
  interval: { timing: 0 },
  animation: 'lazy'
};

export const carouselProductMobile: componentsB4a.B4aCarouselConfig = {
  grid: { xs: 1, sm: 1, md: 4, lg: 5, all: 0 },
  slide: 1,
  speed: 250,
  point: {
    visible: true
  },
  load: 2,
  velocity: 0,
  touch: true,
  easing: 'cubic-bezier(0, 0, 0.2, 1)',
  interval: { timing: 0 },
  animation: 'lazy'
};

export const carouselReview: componentsB4a.B4aCarouselConfig = {
  grid: { xs: 2, sm: 4, md: 4, lg: 4, xl: 4, all: 0 },
  slide: 1,
  speed: 250,
  point: {
    visible: true
  },
  load: 2,
  velocity: 0,
  touch: true,
  easing: 'cubic-bezier(0, 0, 0.2, 1)',
  interval: { timing: 0 },
  animation: 'lazy',
};

export const carouselPress: componentsB4a.B4aCarouselConfig = {
  grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
  slide: 1,
  speed: 250,
  point: {
    visible: true
  },
  load: 1,
  velocity: 0,
  touch: true,
  easing: 'cubic-bezier(0, 0, 0.2, 1)',
  interval: { timing: 5000 },
  animation: 'lazy'
};

export const carouselInstagram: componentsB4a.B4aCarouselConfig = {
  grid: { xs: 2, sm: 2, md: 4, lg: 5, all: 0 },
  slide: 2,
  speed: 250,
  point: {
    visible: true
  },
  load: 2,
  velocity: 0,
  touch: true,
  easing: 'cubic-bezier(0, 0, 0.2, 1)',
  interval: { timing: 0 },
  animation: 'lazy'
};
