import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class MenuHeaderService {
  constructor(
    @Inject(DOCUMENT)
    private _document: Document
  ) {}

  get elements() {
    const header = this._document.getElementById('malmo-header');
    const menu = this._document.getElementById('menu-mobile');
    const overlay = this._document.getElementById('overlay-menu');
    const submenu = this._document.getElementById('submenu');
    const close = this._document.getElementById('close-button');

    return {
      header,
      menu,
      overlay,
      close,
      submenu
    };
  }

  toggle() {
    const { header, menu, overlay, close, submenu } = this.elements;

    header?.classList.toggle('active');
    overlay?.classList.toggle('active');
    menu?.classList.toggle('active');
    submenu?.classList.remove('active');
    close?.classList.toggle('active');



    if (header?.classList.contains('active')) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }

  close() {
    const { header, menu, overlay, close, submenu } = this.elements;

    if (
      header?.classList.contains('active') &&
      overlay?.classList.contains('active')
    ) {
      header.classList.remove('active');
      menu?.classList.remove('active');
      close?.classList.remove('active')
      overlay.classList.remove('active');
      submenu.classList.remove('active');

      document.body.style.overflow = 'auto';
    }
  }

  togleSubmenu() {
    const { submenu } = this.elements;

    submenu?.classList.toggle('active');
  }
}
