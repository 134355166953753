import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BrandLeadService } from 'src/app/core/services/api/brand-lead.service';

import { LeadModel } from '../../models/lead.model';
import { ResponseNewletter } from '../../models/responseNewletter';

@Component({
  selector: 'malmo-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit, OnDestroy {
  @ViewChild('newsletter', { static: false }) newsletter:
    | ElementRef<HTMLInputElement>
    | undefined;

  formLead: FormGroup;
  loading: boolean;

  submitted = false;

  show = true;

  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  constructor(
    private _brandLeadService: BrandLeadService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.loading = false;
  }

  get stateName() {
    return this.show ? 'show' : 'hide';
  }

  get stateSend() {
    return this.show ? 'hide' : 'show';
  }

  get formLeadControl() {
    return this.formLead.controls;
  }

  initForm(): void {
    this.formLead = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern),
        ]),
      ],
    });
  }

  getClass(formLeadControl) {
    if (formLeadControl === this.formLeadControl.name) {
      if (
        (this.formLeadControl.name.touched || this.submitted) &&
        this.formLeadControl.name.errors?.required
      ) {
        return 'error';
      } else if (
        (this.formLeadControl.name.touched || this.submitted) &&
        this.formLeadControl.name.valid
      ) {
        return 'success';
      } else {
        return '';
      }
    }
    if (formLeadControl === this.formLeadControl.email) {
      if (
        (this.formLeadControl.email.touched || this.submitted) &&
        this.formLeadControl.email.errors?.required
      ) {
        return 'error';
      } else if (this.formLeadControl.email.valid) {
        return 'success';
      } else {
        return '';
      }
    } else {
      return;
    }
  }

  setObject() {
    return {
      name: this.formLead.value.name,
      email: this.formLead.value.email,
      source: 'Malmo',
      acceptsNewsletter: true,
    };
  }

  async onSubmit(): Promise<void> {
    this.submitted = true;
    if (this.formLead.valid) {
      try {
        const requestPayload: LeadModel = this.setObject() as LeadModel;
        this.loading = true;
        await this._brandLeadService
          .registerNewsletter(requestPayload)
          .then((res: ResponseNewletter) => {
            res;
            this.submitted = false;
            this.formLead.reset();
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
      } finally {
        this.loading = false;
        this.submitted = false;
        this.show = !this.show;
        setTimeout(() => {
          this.show = !this.show;
        }, 10000);
      }
    }
  }
}
