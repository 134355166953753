<div id="reviews-component">
    <div class="wrapper">
        <h2 class="wrapper__title">Avaliações</h2>

        <ng-container *ngIf="loading; else loadedProducts">
            <div class="content-loading">
                <ngx-skeleton-loader
                    [count]="getSkeletonCount()"
                    [theme]="getSkeletonTheme()"
                ></ngx-skeleton-loader>
            </div>
        </ng-container>

        <ng-template #loadedProducts>
            <div
                class="content"
                *ngIf="reviewedProducts?.length > 0; else noExistsPublishedProducts"
            >
                <div class="desktop-content">
                    <components-b4a-carousel
                        #carouselReview
                        [inputs]="carouselTile"
                        [dataSource]="reviewedProducts"
                        (onMove)="onCarouselMove($event)"
                    >
                        <b4a-tile *b4aCarouselDef="let item; let i = index">
                            <malmo-review-card [reviewedProduct]="item"></malmo-review-card>
                        </b4a-tile>
                        <button
                            B4aCarouselPrev
                            class="prev"
                            [style.opacity]="carouselReview.isFirst ? 0.5 : 1"
                            (click)="prev()"
                        >
                            <img
                                src="../../../assets/img/svg/outline-previous-arrow.svg"
                                alt="botão voltar"
                            >
                        </button>
                        <button
                            B4aCarouselNext
                            class="next"
                            [style.opacity]="carouselReview.isLast ? 0.5 : 1"
                            (click)="next()"
                        >
                            <img
                                src="../../../assets/img/svg/outline-next-arrow.svg"
                                alt="botão próximo"
                            >
                        </button>
                        <ul
                            class="dots"
                            B4aCarouselPoint
                            *ngIf="points?.length > 0"
                        >
                            <li
                                *ngFor="let i of points; let index"
                                [class.active]="i==carouselReview.activePoint"
                                (click)="moveTo(carouselReview, index)"
                            ></li>
                        </ul>
                    </components-b4a-carousel>
                </div>

                <div
                    class="mobile-content"
                    [ngStyle]="!more && i < 2 ? {'margin-bottom': '16px !important'} : more ? {'margin-bottom': '16px'} : {'margin-bottom':'0px'}"
                    *ngFor="let reviewedProduct of reviewedProducts; let i = index"
                >
                    <malmo-review-card
                        *ngIf="!more && i < 2"
                        [reviewedProduct]="reviewedProduct"
                    ></malmo-review-card>

                    <malmo-review-card
                        *ngIf="more"
                        [reviewedProduct]="reviewedProduct"
                    ></malmo-review-card>
                </div>
            </div>

            <ng-template #noExistsPublishedProducts>
                <div class="without-publics-products">
                    <span>Não há produtos publicados.</span>
                </div>
            </ng-template>
        </ng-template>

        <button
            class="btn-outline-white button-mobile"
            (click)="seeMore()"
        >
            {{buttonText}}
        </button>

    </div>
</div>
